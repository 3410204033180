<!-- 关注的用户 -->
<template>
  <div class="my_group">
    <!-- <p class="myclass-title">
      <span class="title-text">我的小组</span>
      <span v-show="count>0">（{{ count }}）</span>
      <span class="title-border"></span>
    </p> -->
    <headers ref="headers" :headname="headName" :count="count"></headers>

    <!-- 有数据展示 -->
    <div class="group_list">
      <ul class="zhul_xz_gzList">
        <li v-for="(item,index) in list" :key="index" @click="groupDetail(item)">
          <p class="zhul_ft_yqLimg">
            <img :src="item.avatar" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'" width="46" height="46" :title="item.username">
          </p>
          <div class="zhul_zy_rmjr">
            <div class="zhul_xz_jrlx">
              <p><b :title="item.old_group_name">{{ item.username }}</b></p>
              <p class="marginTop">
                <img width="14" src="../../../assets/fensi.png">
                <span class="red"></span>{{ countParse(item.num) }}个粉丝&nbsp;&nbsp;
                <img width="15" src="https://newoss.zhulong.com/edu/202007/29/44/121244cbondyllgzczt6un.png">
                {{ countParse(item.count) }}人关注
              </p>
            </div>
          </div>
        </li>
      </ul>

      <!-- 分页 -->
      <div class="feny">
        <el-pagination
          v-show="count>15"
          class="page"
          background
          layout="prev, pager, next"
          :page-size="params.limit"
          :total="count"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>

    </div>
    <!-- 无数据展示 -->
    <noData v-show="count===0 && commend.length === 0" ref="noData" :msg="msg"></noData>

    <!-- 推荐关注 -->
    <div v-show="commend.length > 0 && !isZkb" class="commond_att">
      <div class="item_title">
        <p>推荐关注</p>
      </div>
      <div class="comm_box">
        <ul>
          <li v-for="(item) in commend" :key="item.index">
            <div class="item_list">
              <img onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'" :src="item.avatar" alt="" @click="groupDetail(item)">
              <p class="name" @click="groupDetail(item)">{{ item.username }}</p>
              <p class="spcial">{{ item.from }}</p>
              <div v-if="item.is_follow===1" class="yiguanzhu" @click="cancelFollowUser(item)">已关注</div>
              <div v-else class="guanzhu" @click="followsUser(item)">关注</div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- <div v-show="count===0" class="noDataShow">
      <img src="@/assets/image/kong_group.png" alt="">
      <p>您还没有关注的小组~</p>
    </div> -->

  </div>
</template>

<script>
import { Message } from 'element-ui'
import { myOfUser, follows, cancelfollow } from '@/api/center.js'
import { followList } from '@/api/regInfo.js'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
import Cookies from 'js-cookie'
export default {
  name: '',
  components: { headers, noData },
  data() {
    return {
      isZkb: false,
      headName: '我关注的用户',
      msg: '您还没有关注的用户~',
      count: undefined,
      list: [],
      commend: [], // 推荐数据
      detail_url: '',
      params: {
        page: 1,
        limit: 15
      },
      agency_id: ''
    }
  },

  computed: {
    countParse() {
      return (num) => {
        if (num.toString().length > 4) {
          return parseFloat((num / 1000).toFixed(1)) + 'k'
        } else {
          return num
        }
      }
    }
  },

  created() {
    this.agency_id = Cookies.get('agency_id')
    const origin = window.location.origin
    if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    this.getlist()
    if (!this.isZkb) {
      this.getCommentFollows()
    }
  },

  mounted() {},

  methods: {
    getlist() {
      myOfUser(this.params).then(res => {
        this.list = res.result.list
        this.count = res.result.count
        // this.count = res.result.count
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取推荐数据
    getCommentFollows() {
      const post = {
        limit: 6,
        page: 1
      }
      followList(post).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          const list = result.list ? result.list : []
          for (const i in list) {
            if (!list[i].is_follow) {
              list[i].is_follow = 0
            }
          }
          if (list.length > 6) {
            this.commend = list.slice(0, 6)
          } else {
            this.commend = list
          }
        }
      })
    },
    // 关注用户
    followsUser(item) {
      const param = { uid: item.uid }
      follows(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          item.is_follow = 1
        } else {
          const msg = res.msg
          Message({ message: msg, type: 'error' })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 取消关注用户
    cancelFollowUser(item) {
      const param = { uid: item.uid }
      cancelfollow(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          item.is_follow = 0
        } else {
          const msg = res.msg
          Message({ message: msg, type: 'error' })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    groupDetail(item) {
      console.log(item, 'item')
      this.$router.push({
        path: '/archive',
        query: {
          u: item.uid
        }
      })
      // window.open(`https://www.zhulong.com/ucenter/archive?u=${item.uid}`)
    },

    // 分页
    handleCurrentChange(val) {
      // 当前点击页数
      this.params.page = val
      this.getlist()
    }
  }

}
</script>

<style lang="scss" scoped>
.my_group{
	width: 965px;
	// padding: 0 20px 20px 20px;
  padding: 0 0 20px 0;
	min-height: 900px;
  position: relative;
  font-family: 'PingFangSC-Regular';
}
.myclass-title{
	padding: 26px 60px 12px 10px;
	width: 100%;
	font-size: 19px;
	position: relative;
	text-align: left;
}
.title-border{
	position: absolute;
	left: 0;
	bottom: 16px;
	width: 4px;
	height: 20px;
	z-index: 2;
	background: #f04e4f;
}

.zhul_xz_gzList{
	float: left;
	margin-left: 30px;
	width: 905px;
}
.zhul_xz_gzList li{
	float: left;
	width: 285px;
	padding: 16px 11px;
  padding-right: 0;
	margin: 20px 20px 0 0;
	box-shadow: 0 0 8px 0 rgba(0,0,0,0.12);
	cursor: pointer;
}
.zhul_xz_gzList li:nth-of-type(3n){
	margin-right: 0;
}
.zhul_ft_yqLimg{
	float: left;
	width: 46px;
	height: 46px;
	border-radius: 4px;
	overflow: hidden;
}
.zhul_zy_rmjr{
	float: left;
  width: 220px;
  padding-left: 10px;
  line-height: 26px;
	color: #999;
}
.zhul_xz_jrlx {
    float: left;
    width: 100%;
}
.zhul_xz_jrlx p {
    height: 18px;
    overflow: hidden;
    line-height: 18px;
}
.zhul_zy_rmjr b{
    display: block;
    width: 190px;
    height: 24px;
    font-size: 18px;
    color: #444444;
		text-align: left;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}
.red{
	color:#EE2E2F;
}
.marginTop{
	margin-top:10px;
	text-align: left;
}
.marginTop img{
	vertical-align:sub;
	margin-right: 4px;
	margin-bottom: 1px;
}
.noDataShow{
	position: absolute;
	width: 180px;
	height: 180px;
	top: 50%;
	left: 50%;
	margin-top: -90px;
}
.noDataShow img {
	display: block;
	margin: auto;
}
.noDataShow p {
	text-align: center;
	font-size: 14px;
	color: #999999;
	margin-top: 30px;
}
.feny{
	display: inline-block;
	margin-top: 20px;
}

.item_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  p {
    display: inline-block;
    font-size: 19px;
    font-weight: bold;
    height: 100%;
    line-height: 40px;
    color: #333333;
    font-family: MicrosoftYaHei-Bold;
    border-bottom: 4px solid #ee2e2e;
  }
  .tit_r {
    width: 300px;
    height: 30px;
    border-radius: 15px;
    border: solid 1px #e50f15;
    font-family: "PingFangSC-Regular";
    // font-weight: bold;
    font-size: 15px;
    line-height: 28px;
    color: #e50f15;
    cursor: pointer;
  }
}
.commond_att {
  background-color: #fff;
  width: 100%;
  margin-top: 15px;
  padding: 30px 28px;
  float: left;
  .comm_box {
    background-color: #fff;
    width: 100%;
    margin-top: 35px;
    display: inline-block;
    height: 190px;
    li {
      display: inline-block;
      width: 131px;
      height: 182px;
      text-align: center;
      box-shadow: 0px 0px 5px #999;
      margin-top: 4px;
      margin-right: 20px;
      border-radius: 5px;
      .item_list {
        width: 100%;
        height: 100%;
        img {
          margin-top: 16px;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          cursor: pointer;
        }
        .name {
          margin: 13px 0 0 0;
          height: 20px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          line-height: 20px;
          color: #444444;
          cursor: pointer;
        }
        .spcial {
          margin: 10px 0 18px 0;
          height: 15px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          line-height: 20px;
          color: #999999;
        }
        .guanzhu {
          width: 75px;
          height: 23px;
          background-color: #ee2e2e;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;
          margin: auto;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .yiguanzhu {
          width: 75px;
          height: 23px;
          background-color: #999999;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;
          margin: auto;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
